// For hiding block of all text messages which are needed for tooltips.
.cdk-visually-hidden {
  display: none;
}

// Our own material tooltip styles
mat-tooltip-component {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: #0299db transparent transparent transparent;
  }
}

.mat-tooltip {
  padding: 12px 18px !important;
  border-radius: 20px !important;
  background-color: #0299db;
  font-size: 12px;
  color: #FFFFFF !important;
}
