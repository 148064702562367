// https://google-webfonts-helper.herokuapp.com/fonts/roboto?subsets=cyrillic,cyrillic-ext,latin,latin-ext
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: local(""), url("/assets/stusan/fonts/roboto-400.woff2") format("woff2"),
    url("/assets/stusan/fonts/roboto-400.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("/assets/stusan/fonts/roboto-500.woff2") format("woff2"),
    url("/assets/stusan/fonts/roboto-500.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  src: local(""), url("/assets/stusan/fonts/roboto-700.woff2") format("woff2"),
    url("/assets/stusan/fonts/roboto-700.woff") format("woff");
}

@font-face {
  font-family: 'Cooper Hewitt';
  src: local(""), url('/assets/stusan/fonts/cooper-hewitt/cooper-hewitt.woff2') format('woff2'),
  url('/assets/stusan/fonts/cooper-hewitt/cooper-hewitt.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
