:root, [data-theme="light"] {
  --background-color: #F5F4FC;
  --background-color-controls: #FFF;
  --background-color-aside: #f2fbff;
  --text-color: #261362;
  --menu-color: #000;
  --menu-hover-color: #0299DB;

  --avatar-text-color: #fff;
  // TODO: use different border-colors for dark mode
  --border-color: #F1F1F1;

  --primary-color: var(--text-color);
  --primary-color-hover: rgba(38, 19, 98, 0.8); // #261362

  --header-height: 69px;
  --controls-height: 79px;
  --controls-height-mobile: 64px;
  --side-menu-width: 341px;
  --icon-bg-color: #7D71A1;
  --icon-arrow: #7D71A1;
  --menu-height: 100vh;
  --menu-outer-color: #fff;
  --menu-item-static: #000;
  --menu-item-static-outer: #fff;
  --menu-item-disabled: #EC663B;
  --menu-item-disabled-outer: #FFCDBB;
  --menu-item-finish-border: #F1F1F1;
  --finish-call-color: #FC573B;
  --finish-call-background: #FFCDBB;
  --finish-call-border: #fff;
  --finish-call-hover: #f6ad93;
  --chat-width: 360px;
  --chat-height: 100vh;
  --chat-background: #F6F6F6;
  --chat-color: #000;
  --chat-border: rgba(0,0,0,.14);
  --chat-title: #000;
  --chat-icon: #000;
  --chat-input: #fff;
  --chat-palceholder: rgba(39,45,47,.5);
  --chat-submit: #000;
  --chat-text: #DFE7FC;
  --chat-info: rgba(39,45,47,.5);
  --chat-own: #fff;
  --chat-avatar: #eae9f0;
  --header-users-bg: rgba(255, 255, 255, 0.5);
  --header-users-icon: #261362;
  --header-live-bg: rgba(255, 255, 255, 0.5);
  --header-live-icon: #FC573B;
  --settings-title-color: rgba(38, 19, 98, 1);
  --settings-close-color: #261362;
  --settings-hover-close-color: rgba(179, 179, 179, 0.3);
  --settings-icon-color: #1E2021;
  --dropdown-bg-color: #fff;
  --dropdown-border-color: #272D2F;
  --dropdown-arrow-color: #272D2F;
  --dropdown-icon-color: #272D2F;
  --dropdown-text-color: #272D2F;
  --videos-scroll-bg: rgba(255, 255, 255, 0.3);
  --mic-color: #000;
  --activity-color-from: #fff;
  --activity-color-to: #a3deea;
  --share-color: #675A91
}

.layouts__item--active [icon=layout-share] {
  --share-color: #FC8B01;
}

[data-theme="dark"] {
  --background-color: #000;
  --background-color-controls: #272d2f;
  --background-color-aside: #1e2021;
  --text-color: #FFF;
  --border-color: #272D2F;
  --avatar-text-color: #272D2F;
}

[data-theme="blue"] {
  --icon-bg-color: #002146;
  --background-color: rgba(0, 33, 69, 255);
  --background-color-controls: #04182E;
  --text-color: #fff;
  --border-color: #04182E;
  --menu-color: #fff;
  --menu-outer-color: rgba(0, 33, 70, 0.14);
  --menu-item-static: #fff;
  --menu-item-static-outer: transparent;
  --menu-item-disabled: #FFCC00;
  --menu-item-disabled-outer: #FFEEA9;
  --menu-item-finish-border: #003673;
  --finish-call-color: #FFCC00;
  --finish-call-background: #002145;
  --finish-call-border: #003673;
  --finish-call-hover: #04182E;
  --chat-background: #003673;
  --chat-color: #fff;
  --chat-border: rgba(0,0,0,.14);
  --chat-title: #FFCC00;
  --chat-icon: #FFCC00;
  --chat-input: #04182E;
  --chat-palceholder: #DFE7FC;
  --chat-submit: #fff;
  --chat-text: #4291E9;
  --chat-info: rgba(211, 229, 250, 0.5);
  --chat-own: #04182E;
  --chat-avatar: #04182E;
  --header-users-bg: #003673;
  --header-users-icon: #fff;
  --header-live-bg: #4291E9;
  --header-live-icon: #FFCC00;
  --settings-title-color: #fff;
  --settings-close-color: #fff;
  --dropdown-bg-color: #003673;
  --dropdown-icon-color: #fff;
  --dropdown-border-color: #003673;
  --dropdown-arrow-color: #fff;
  --videos-scroll-bg: transparent;
  --icon-arrow: #fff;
  --mic-color: #fff;
  --activity-color-from: #04182E;
  --activity-color-to: #FFEEA9;
}
