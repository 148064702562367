@import '~ngx-toastr/toastr';
@import "~normalize.css";
@import "assets/stusan/styles/angular-material";
@import "assets/stusan/styles/variables";
@import "assets/stusan/styles/fonts";
@import "assets/stusan/styles/form-elements";
@import "~@angular/cdk/overlay-prebuilt.css";
@import "screen-breakpoints";
@import "flag-icons-svg/sass/flag-icons";
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Scrolling styles
::-webkit-scrollbar {
  width: 10px; // For vertical scrollbars
  height: 10px; // For horizontal scrollbars
}
::-webkit-scrollbar-track {
  // Track
  background: transparent;
}
::-webkit-scrollbar-thumb {
  // Handle
  background: var(--primary-color);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  // Handle on hover
  background: var(--primary-color-hover);
}

html {
  font-family: "Roboto", sans-serif;
  // Scrolling styles for Firefox
  scrollbar-color: var(--primary-color) transparent;
  scrollbar-width: thin;
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

p {
  margin: 10px 0;
}

button {
  cursor: pointer;
  padding: 0;
  transition: box-shadow 0.3s;

  &:hover,
  &:focus {
    box-shadow: 0 0 4px var(--form-element-border-color);
    outline: none;
  }
}

.dialog-backdrop {
  background: rgba(30, 32, 33, 0.7);
  opacity: 0.7;
}

.dropdown-panel {
  ng-component {
    width: 100%;
  }
}

@media (max-height: 1000px) {
  .settings-dialog {
    margin-top: 100px !important;
  }
}

@media (max-height: 900px) {
  .settings-dialog {
    margin-top: 20px !important;
    .container {
      .scrollable {
        max-height: calc(100vh - 20px) !important;
      }
    }
  }
}

.main-controls stusan-icon.exit-room-icon {
  display: none;
}

[data-theme="blue"] {
  .logo-container img.logo {
    width: 142px;
    //height: 46px;
  }

  .login img.login__logo {
    width: 283px;
    //height: 91px;
  }

  .login footer.login__footer {
    color: #fff;
  }

  @media (min-width: map-get($screen-breakpoints, lg)) {
    .chat .chat__close {
      display: none;
    }
  }

  .main-controls stusan-icon.finish-call-icon {
    display: none;
  }

  .main-controls stusan-icon.exit-room-icon {
    display: inline-block;
  }

  div.icon-outer {
    width: 30px !important;
    height: 30px !important;
    border-radius: 6px !important;

    stusan-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }
  .mic-off stusan-icon,
  .fullscreen stusan-icon,
  .network stusan-icon {
    fill: #FC0 !important;
  }

  .mic-off,
  .fullscreen,
  .network {
    display: none;
  }

  .container .like {
    bottom: 16px !important;
  }

  .name-wrapper {
    max-width: 80% !important;
  }

  .invisible {
    display: none;
  }
}

[data-theme="light"] {
  .chat__tabs {
    background: transparent !important;
  }

  .chat__title--chat {
    pointer-events: none;
  }

  .chat__title--users {
    display: none;
  }
}

mat-select {
  background-color: var(--dropdown-bg-color);
  border-radius: 20px;
  height: 40px;
  border: 1px solid var(--dropdown-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 37px;
  padding-left: 13px;
  padding-right: 9px;
  box-sizing: border-box;

  .mat-select-value-text {
    color: var(--dropdown-text-color);
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 15px 0;
  }
}

.mat-option {
  color: var(--dropdown-text-color);
  font-size: 14px !important;
  line-height: 16px !important;
  height: 40px !important;
}

.mat-select-arrow-wrapper {
  position: relative;
}

.mat-select-arrow-wrapper::after {
  position: absolute;
  left: -7px;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  content: url("./assets/icons/dropdown/dropdown-arrow.svg");
}

.mat-select-arrow {
  visibility: hidden;
}

.thevatra-select {
  background-color: var(--dropdown-bg-color);
  border: 1px solid var(--dropdown-border-color);
  border-radius: 20px !important;
  cursor: default;
  min-width: 0 !important;
  width: 281px !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.thevatra-select .mat-option:hover {
  background-color: #048fd3;
  color: white;
}

.toast {
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  opacity: 0.8;
  padding: 20px 25px;
  margin-bottom: 10px;
  color: #ffffff;
  background: rgba(39, 45, 47, 0.7);
  max-width: 300px;
  z-index: 9999999;
  backdrop-filter: blur(-10px);
}

.toast-top-right {
  top: 60px;
  right: 12px;
}

.visibility-hidden {
  visibility: hidden;
}

